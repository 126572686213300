import { createContext } from 'react';

import { useWindowSize } from 'hooks/resize';

export const DeviceContext = createContext(null);
export const MenuContext = createContext(null);

const LocaleLayout = ({ children }) => {
	const isMobile = useWindowSize(430);
	const isTablet = useWindowSize();

	return (
		<DeviceContext.Provider value={{ isMobile, isTablet }}>
			{children}
		</DeviceContext.Provider>
	);
};

export default LocaleLayout;
