// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-bonds-index-js": () => import("./../../../src/layouts/bonds/index.js" /* webpackChunkName: "component---src-layouts-bonds-index-js" */),
  "component---src-layouts-docs-index-js": () => import("./../../../src/layouts/docs/index.js" /* webpackChunkName: "component---src-layouts-docs-index-js" */),
  "component---src-layouts-legals-index-js": () => import("./../../../src/layouts/legals/index.js" /* webpackChunkName: "component---src-layouts-legals-index-js" */),
  "component---src-layouts-post-index-js": () => import("./../../../src/layouts/post/index.js" /* webpackChunkName: "component---src-layouts-post-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-index-js": () => import("./../../../src/pages/about-company/index.js" /* webpackChunkName: "component---src-pages-about-company-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookie-declaration-index-js": () => import("./../../../src/pages/cookie-declaration/index.js" /* webpackChunkName: "component---src-pages-cookie-declaration-index-js" */),
  "component---src-pages-downloads-index-js": () => import("./../../../src/pages/downloads/index.js" /* webpackChunkName: "component---src-pages-downloads-index-js" */),
  "component---src-pages-free-trial-index-js": () => import("./../../../src/pages/free-trial/index.js" /* webpackChunkName: "component---src-pages-free-trial-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-note-from-founder-index-js": () => import("./../../../src/pages/note-from-founder/index.js" /* webpackChunkName: "component---src-pages-note-from-founder-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-students-index-js": () => import("./../../../src/pages/students/index.js" /* webpackChunkName: "component---src-pages-students-index-js" */),
  "component---src-pages-videos-index-js": () => import("./../../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */)
}

