import { useState, useEffect } from "react";

export const useWindowSize = (breakPoint = 768) => {
  const [lessThenBreakPoint, setLessThenBreakPoint] = useState();

  const handleMediaQueryChanges = (e) => setLessThenBreakPoint(e.matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakPoint}px)`);
    handleMediaQueryChanges(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChanges);
    return () => mediaQuery.removeListener(handleMediaQueryChanges);
  }, []);

  return lessThenBreakPoint;
};
