require("./src/styles/style.scss");

const React = require("react")
const LocaleLayout = require("./src/layouts/context").default

exports.wrapRootElement = ({ element }) => {
  return (
    <LocaleLayout>
      {element}
    </LocaleLayout>
  )
}
